import { React, useEffect, useState } from "react";
import {
  auth,
  signInWithEmailAndPassword,
  signInWithGoogle,
} from "../firebase";
import {
  ShoppingBagIcon,
  CheckCircleIcon,
  XCircleIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { registerWithEmailAndPassword } from "../firebase";
import classNames from "classnames";
export const Register = () => {
  const [user, loading, error] = useAuthState(auth);
  const [countDown, setCountDown] = useState(4);
  const [geslo, setGeslo] = useState("");
  const [email, setEmail] = useState("");
  const [ime, setIme] = useState("");
  const navigate = useNavigate();
  const [signInLoading, setSignInLoading] = useState(false);
  const [overlay, showOverlay] = useState("hidden");
  const [errorOverlay, setErrorOverlay] = useState("hidden");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");
  const [gesloError, setGesloError] = useState(false);
  const [gesloMessage, setGesloMessage] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showPass, setShowPass] = useState("password");
  const [intervalId, setIntervalId] = useState(null);

  const submitRegister = (e) => {
    e.preventDefault();
    setSignInLoading(true);
    registerWithEmailAndPassword(ime, email, geslo).then((response) => {
      const signIn = response;

      switch (signIn) {
        case "auth/weak-password":
          setBtnDisabled(true);
          setSignInLoading(false);
          setGesloError(true);
          setGesloMessage("Geslo je prešibko.");
          break;
        case "auth/email-already-exists":
          setBtnDisabled(true);
          setSignInLoading(false);
          setEmailError(true);
          setEmailMessage("Email že obstaja.");
          break;
        case "auth/email-already-in-use":
          setBtnDisabled(true);
          setSignInLoading(false);
          setEmailError(true);
          setEmailMessage("Email že obstaja.");
          break;
        case "auth/internal-error":
          setBtnDisabled(true);
          setSignInLoading(false);
          setErrorOverlay("flex");
          break;
        case "auth/invalid-credential":
          setBtnDisabled(true);
          setSignInLoading(false);
          setErrorOverlay("flex");
          break;
        case "auth/invalid-email":
          setBtnDisabled(true);
          setSignInLoading(false);
          break;
        case "ok":
          setSignInLoading(false);
          timer();
          showOverlay("flex");
          setSignInLoading(false);
          break;
        default:
          setSignInLoading(false);
          setErrorOverlay("flex");
      }
    });
  };

  const checkSubmitBtnMail = () => {
    if (btnDisabled === true) {
      setEmailError(false);
      setEmailMessage("");
    }
    if (gesloError == false && emailError == false) {
      setBtnDisabled(false);
    }
  };
  const checkSubmitBtnGeslo = () => {
    if (btnDisabled === true) {
      setGesloError(false);
      setGesloMessage("");
    }
    if (gesloError == false && emailError == false) {
      setBtnDisabled(false);
    }
  };
  async function timer() {
    setInterval(() => {
      setCountDown((countDown) => countDown - 1);
      console.log(countDown);
    }, 1000);
    await delay(4000);
    console.log("gremo");
    navigate("/login");
  }
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    checkSubmitBtnMail();
  }, [email]);
  useEffect(() => {
    checkSubmitBtnGeslo();
  }, [geslo]);
  return (
    <>
      <div className="flex min-h-screen bg-slate-200 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            <Link to="/login" className="text-gray-400 font-normal">
              Registracija
            </Link>
            <span className="mx-2">/</span>
            <span>Prijava</span>
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md relative">
          <div
            className={classNames(
              overlay,
              "absolute z-10 animate-fade text-center w-full  bg-inherit h-full justify-center items-center"
            )}
          >
            <div>
              <CheckCircleIcon className="w-24 h-24 mx-auto text-green-300 animate-wave" />
              <h2 className="text-center text-xl font-semibold mt-2">
                Uspešna registracija!
              </h2>
              <p>Preusmerjam... {countDown}</p>
            </div>
          </div>
          <div
            className={classNames(
              errorOverlay,
              "absolute animate-fade z-10 text-center w-full  bg-inherit h-full justify-center items-center"
            )}
          >
            <div>
              <XCircleIcon className="w-24 h-24 mx-auto text-red-300 animate-wave" />
              <h2 className="text-center text-xl font-semibold mt-2">
                Prišlo je do napake. {errorMessage}
              </h2>
              <p>Preusmerjam... {countDown}</p>
            </div>
          </div>
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={submitRegister}
            >
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ime
                </label>
                <div className="mt-1 relative">
                  <input
                    id="ime"
                    name="ime"
                    type="text"
                    autoComplete="current-password"
                    required
                    value={ime}
                    onChange={(e) => setIme(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={classNames(
                      emailError ? "border-red-500" : "border-gray-300",
                      "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    )}
                  />
                  <span className="text-sm text-red-500"> {emailMessage}</span>
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Geslo
                </label>
                <div className="mt-1 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPass}
                    autoComplete="current-password"
                    required
                    value={geslo}
                    onChange={(e) => setGeslo(e.target.value)}
                    className={classNames(
                      gesloError ? "border-red-500" : "border-gray-300",
                      "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    )}
                  />
                  {showPass == "password" ? (
                    <EyeIcon
                      onClick={() => setShowPass("text")}
                      className="absolute w-6 h-6 top-2 right-2 text-gray-400 cursor-pointer"
                    />
                  ) : (
                    <EyeSlashIcon
                      onClick={() => setShowPass("password")}
                      className="absolute w-6 h-6 top-2 right-2 text-gray-400 cursor-pointer"
                    />
                  )}
                  <span className="text-sm text-red-500"> {gesloMessage}</span>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Zapomni si me
                  </label>
                </div>

                <div className="text-sm">
                  <Link
                    to="/login"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Prijava
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  disabled={btnDisabled}
                >
                  {signInLoading && (
                    <svg
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                  Prijavi se
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
