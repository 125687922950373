import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./fonts/Inter-Black.ttf";
import "./fonts/Inter-Bold.ttf";
import "./fonts/Inter-ExtraBold.ttf";
import "./fonts/Inter-ExtraLight.ttf";
import "./fonts/Inter-Light.ttf";
import "./fonts/Inter-Medium.ttf";
import "./fonts/Inter-Regular.ttf";
import "./fonts/Inter-SemiBold.ttf";
import "./fonts/Inter-Thin.ttf";

import Home from "./pages/Home";
import LayoutNav from "./pages/LayoutNav";
import Users from "./pages/Users";
import Notify from "./pages/Notify";
import { Login } from "./pages/login";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import ScrollToTop from "./components/ScrollToTop";
import { Register } from "./pages/Register";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
      </Route>
      <Route element={<LayoutNav />}>
        <Route index element={<Home />} />
        <Route path="users" element={<Users />} />
        <Route path="notify" element={<Notify />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
