import { React, useState, useRef, Fragment } from "react";
import PredlogBlock from "../components/PredlogBlock";
import { Dialog, Transition } from "@headlessui/react";
import { db } from "../firebase";
import { auth } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { TopBar } from "../components/TopBar";
const Notify = () => {
  const [openDodajMesto, setOpenDodajMesto] = useState(false);
  const cancelButtonRef = useRef(null);
  const [ime, setIme] = useState("");
  const [user, userLoading, userError] = useAuthState(auth);
  const [telefon, setTelefon] = useState("");
  const dodajUporabnika = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "mesta"), {
        ime: ime,
        telefon: telefon,
        createdAt: serverTimestamp(),
      });
    } catch (error) {
      console.log(error);
    }

    setIme("");
    setTelefon("");
    setOpenDodajMesto(false);
    console.log(ime, telefon);
  };

  return (
    <>
      <TopBar naslov="Domov" />
      <div className="bg-slate-200 min-h-screen p-2 z-0">
        <div className="grid grid-cols-2 gap-2 md:gap-4 md:grid-cols-4">
          {user && (
            <PredlogBlock
              userDisplayName={user.displayName}
              datum={Date.now()}
            />
          )}
        </div>
        <button
          //onClick={() => setOpen(open ? false : true)}
          className="mx-auto block min-w-[70%] p-5 mt-5  m-1 rounded-sm bg-white text-gray-700"
        >
          Obvesti sodelavce
        </button>
      </div>
      <Transition.Root show={openDodajMesto} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenDodajMesto}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full md:w-auto transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Dodaj mesto
                      </Dialog.Title>
                      <form onSubmit={dodajUporabnika} id="addUporabnikaForm">
                        <div className="mt-2 min-w-full sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <div className="w-full">
                            <label
                              htmlFor="email"
                              className="block  text-left rounded-md text-sm font-medium text-gray-700"
                            >
                              Ime
                            </label>
                            <input
                              type="text"
                              name="ime"
                              id="ime"
                              value={ime}
                              className="mt-2 inline-flex w-full justify-center rounded-md border-gray-300 shadow-sm px-4 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="ime"
                              onChange={(e) => setIme(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="mt-4 min-w-full sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <div className="w-full">
                            <label
                              htmlFor="email"
                              className="block text-left rounded-md text-sm font-medium text-gray-700"
                            >
                              Telefon
                            </label>
                            <input
                              type="text"
                              name="email"
                              id="email"
                              value={telefon}
                              className="mt-2 inline-flex w-full justify-center rounded-md border-gray-300 shadow-sm px-4 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="telefon"
                              onChange={(e) => setTelefon(e.target.value)}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      form="addUporabnikaForm"
                    >
                      Shrani
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpenDodajMesto(false)}
                      ref={cancelButtonRef}
                    >
                      Prekliči
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Notify;
