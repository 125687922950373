import { React, useState, useEffect, Fragment } from "react";
import { UserIcon } from "@heroicons/react/24/outline";
import { db } from "../firebase";
import Loader from "./Loader";
import { collection, orderBy, query } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
const UserBlock = () => {
  const citiesRef = collection(db, "uporabniki");
  const q = query(citiesRef, orderBy("createdAt"));

  const [value, loading, error] = useCollectionData(q, {
    snapshotListenOptions: { includeMetadataChanges: true },
    snapshotOptions: { idField: "id", orderBy: "createdAt" },
  });

  useEffect(() => {
    //fetchBlogs();
  }, []);
  if (loading) {
    return <Loader />;
  } else {
    return (
      <>
        {value &&
          value.map((doc, key) => (
            <Fragment key={doc.id}>
              {" "}
              <div
                className="min-w-0 p-2 md:p-5 flex-1  rounded-sm bg-white shadow-md animate-fadein opacity-0"
                style={{ animationDelay: key / 10 + 0.2 + "s" }}
              >
                <a href="#" className="focus:outline-none">
                  <UserIcon className="h-8 w-8 text-blue-500" />

                  <p className="text-sm font-medium text-gray-900">{doc.ime}</p>
                  <p className="truncate text-sm text-gray-500"> {doc.email}</p>
                </a>
              </div>
            </Fragment>
          ))}
      </>
    );
  }
};

export default UserBlock;
