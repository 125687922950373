import { React, useState, useRef, Fragment } from "react";
import GetMenu from "./getMenu";
import {
  ShoppingBagIcon,
  CheckCircleIcon,
  XCircleIcon,
  CheckIcon,
  ChevronUpDownIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import {
  collection,
  query,
  orderBy,
  updateDoc,
  doc,
  where,
} from "firebase/firestore";
import Loader from "./Loader";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../firebase";
import ModalSpecific from "./ModalSpecific";
import { Combobox } from "@headlessui/react";
import classNames from "classnames";

const generalOptions = [
  { id: 1, name: "Cheese burger" },
  { id: 2, name: "Kebab" },
  { id: 3, name: "Jufka" },
  { id: 4, name: "Hot-dog" },
  { id: 5, name: "Sendvič" },
  { id: 6, name: "Pica" },
];

const PredlogBlock = ({ userDisplayName, datum }) => {
  const [notificationModal, setNotificationModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [queryMeal, setQueryMeal] = useState("");
  const [selectedPerson, setSelectedPerson] = useState();
  const [selectedPlace, setSelectedPlace] = useState("");
  const [prevSelectedPlace, setPrevSelectedPlace] = useState("");
  const cancelButtonRef = useRef(null);
  const [customMeal, setCustomMeal] = useState(null);
  const [customMealOption, setCustomMealOption] = useState("");
  const [ime, setIme] = useState("");
  const [detailData, setDetailData] = useState([]);
  const [openOptionModal, setOpenOptionModal] = useState(false);

  const [selectedScreen, setSelectedScreen] = useState(0);
  const [optionsState, setOptionsState] = useState([]);
  const citiesRef = collection(db, "predlogi");
  const current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;
  console.log(date);
  const q = query(
    citiesRef,
    where("createdAt", ">=", new Date(date)),
    orderBy("createdAt", "desc")
  );
  const [value, loading, error] = useCollection(q, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  var filteredMeals =
    queryMeal === ""
      ? generalOptions
      : generalOptions.filter((object) => {
          return object.name.toLowerCase().includes(queryMeal.toLowerCase());
        });

  const za = async (a, id) => {
    const documentID = doc(db, "predlogi", id);
    await updateDoc(documentID, {
      za: a + "," + userDisplayName,
    });
  };
  const changeSelectedPlace = (ime) => {
    if (selectedPlace !== prevSelectedPlace) {
      setCustomMealOption([]);
      setOptionsState([]);
      setSelectedScreen(0);
    }
    setPrevSelectedPlace(selectedPlace);
    setSelectedPlace(ime);
  };
  const addOptions = () => {
    setCustomMealOption("");
    setOptionsState(optionsState.concat(customMealOption));
  };
  const handleClick = () => {
    setCustomMealOption([]);
    setOptionsState([]);

    setCustomMeal({ id: 6, name: queryMeal });
    /*if (!generalOptions.some((u) => u.name === queryMeal)) {
      filteredMeals = queryMeal;
      generalOptions.push({
        id: 6,
        name: queryMeal,
      });
    }*/
    /* generalOptions.filter((object) => {
      console.log(object.name.toLowerCase());
      //return object.name.toLowerCase().includes(queryMeal.toLowerCase());
    });*/
  };
  const selectOption = (e, options, id) => {
    e.preventDefault();
    console.log(id);

    setOptionsState(options);
  };
  const proti = async (a, id) => {
    const documentID = doc(db, "predlogi", id);
    await updateDoc(documentID, {
      proti: a + "," + userDisplayName,
    });
  };
  if (1 > 2) {
    return <Loader />;
  } else {
    return (
      <>
        {value &&
          value.docs.map((doc, key) => (
            <Fragment key={doc.id}>
              <div
                style={{ animationDelay: key / 10 + 0.2 + "s" }}
                className="animate-fadein opacity-0 min-w-0 p-2 md:p-5 flex-1  relative rounded-sm hover:outline duration-100 outline-blue-400  outline-2 bg-white shadow-sm hover:shadow-md h-40 md:h-28"
              >
                <p className="text-md block  absolute top-1 right-2 text-gray-400">
                  {doc.data().createdAt.toDate().toLocaleDateString("sl-SI")}
                </p>
                <div className="grid grid-cols-2 break-inside-avoid-column">
                  <div
                    className=" break-inside-avoid-column h-full"
                    onClick={() => {
                      setOpen(true);
                      changeSelectedPlace(doc.data().ime);
                    }}
                  >
                    <ShoppingBagIcon className="h-8 w-8 text-blue-500" />
                    <p className="text-sm md:text-md  text-gray-900">
                      {doc.data().ime}
                    </p>
                    <p className="truncate block text-sm font-light text-gray-500">
                      {" "}
                      {doc.data().pobudnik}
                    </p>{" "}
                  </div>
                  <div className="w-full  text-right pt-auto align-bottom">
                    <div className="absolute bottom-4 right-4">
                      <div
                        className="inline mx-1 cursor-pointer"
                        onClick={() => {
                          setOpenOptionModal(true);
                          changeSelectedPlace(doc.data().ime);
                          //  za(doc.data().za, doc.id);
                        }}
                      >
                        <span className="text-gray-400 text-sm mr-1 select-none">
                          {doc.data().za && doc.data().za.split(",").length}
                        </span>
                        <CheckCircleIcon className="h-8 w-8 text-slate-400 inline hover:text-green-500" />
                      </div>
                      <div
                        onClick={() => {
                          proti(doc.data().proti, doc.id);
                        }}
                        className="inline mx-1  cursor-pointer"
                      >
                        <span className="text-gray-400 text-sm mr-1 select-none">
                          {doc.data().proti &&
                            doc.data().proti.split(",").length}
                        </span>
                        <XCircleIcon className="h-8 w-8 text-slate-400 inline hover:text-red-500" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}

        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        ></Dialog.Title>
                        <ModalSpecific ime={selectedPlace} />
                        <div className="mt-2">
                          <p className="text-sm text-gray-500"></p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 ">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:col-start-1 sm:mt-0 sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Zapri
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        <Transition.Root show={openOptionModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpenOptionModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4  sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mt-3  sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Meni
                        </Dialog.Title>
                        {selectedScreen ? (
                          <div className="selected">{customMeal.name}</div>
                        ) : (
                          <>
                            <h2 className="mt-3">Izberi popularno:</h2>
                            <GetMenu
                              ime="Viptronik"
                              className="mt-2"
                              selectOption={selectOption}
                            />
                          </>
                        )}

                        {!selectedScreen ? (
                          <>
                            <Combobox
                              as="div"
                              value={customMeal}
                              onChange={setCustomMeal}
                            >
                              <fieldset className="space-y-4 mt-1">
                                {optionsState &&
                                  optionsState.map((element, id) => (
                                    <div
                                      className="relative flex items-start"
                                      key={element}
                                    >
                                      <div className="flex h-5 items-center">
                                        <input
                                          id={element}
                                          aria-describedby={
                                            element + "-description"
                                          }
                                          name={element}
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                      </div>
                                      <div className="ml-3 text-sm">
                                        <label
                                          htmlFor={element}
                                          className="font-medium text-gray-700"
                                        >
                                          {element}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                              </fieldset>

                              <Combobox.Label className="block text-md font-medium text-gray-700 mt-4">
                                Vpiši ročno
                              </Combobox.Label>
                              <div className="relative mt-1">
                                <Combobox.Input
                                  className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                  onChange={(event) =>
                                    setQueryMeal(event.target.value)
                                  }
                                  displayValue={(person) => person?.name}
                                />

                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Combobox.Button>

                                {filteredMeals.length > 0 && (
                                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {filteredMeals.map((element) => (
                                      <Combobox.Option
                                        key={element.id}
                                        value={element}
                                        className={({ active }) =>
                                          classNames(
                                            "relative cursor-default select-none py-2 pl-3 pr-9",
                                            active
                                              ? "bg-indigo-600 text-white"
                                              : "text-gray-900"
                                          )
                                        }
                                      >
                                        {({ active, selected }) => (
                                          <>
                                            <span
                                              className={classNames(
                                                "block truncate",
                                                selected && "font-semibold"
                                              )}
                                            >
                                              {element.name}
                                            </span>

                                            {selected && (
                                              <span
                                                className={classNames(
                                                  "absolute inset-y-0 right-0 flex items-center pr-4",
                                                  active
                                                    ? "text-white"
                                                    : "text-indigo-600"
                                                )}
                                              >
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </Combobox.Option>
                                    ))}
                                  </Combobox.Options>
                                )}
                              </div>
                            </Combobox>
                            <button
                              type="button"
                              className="mt-2 inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => {
                                setSelectedScreen(1);
                                handleClick(Event);
                              }}
                            >
                              Izberi
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="absolute top-8 right-5"
                              onClick={() => {
                                setSelectedScreen(0);
                              }}
                            >
                              <span className="inline mr-2">nazaj</span>
                              <ArrowUturnLeftIcon className="h-4 w-4 inline" />
                            </button>
                            <Combobox
                              as="div"
                              value={customMealOption}
                              onChange={setCustomMealOption}
                            >
                              <fieldset className="space-y-4 mt-5">
                                {optionsState &&
                                  optionsState.map((element, key) => (
                                    <div
                                      className="relative flex items-start"
                                      key={key}
                                    >
                                      <div className="flex h-5 items-center">
                                        <input
                                          id={element}
                                          aria-describedby={
                                            element + "-description"
                                          }
                                          name={element}
                                          defaultChecked="true"
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                      </div>
                                      <div className="ml-3 text-sm">
                                        <label
                                          htmlFor={element}
                                          className="font-medium text-gray-700"
                                        >
                                          {element}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                              </fieldset>

                              <Combobox.Label className="block text-sm font-medium text-gray-700 mt-5">
                                Opcije
                              </Combobox.Label>
                              <div className="relative mt-1">
                                <Combobox.Input
                                  className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                  onChange={(event) =>
                                    setCustomMealOption(event.target.value)
                                  }
                                  displayValue={customMealOption}
                                />

                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Combobox.Button>
                              </div>
                            </Combobox>
                            <button
                              type="button"
                              className="mt-2 inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => {
                                setSelectedScreen(1);
                                addOptions(Event);
                              }}
                            >
                              Dodaj opcijo
                            </button>{" "}
                          </>
                        )}

                        <div className="mt-2">
                          <p className="text-sm text-gray-500"></p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 ">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                        onClick={() => {}}
                      >
                        Potrdi
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  sm:col-start-1 sm:mt-0 sm:text-sm"
                        onClick={() => setOpenOptionModal(false)}
                        ref={cancelButtonRef}
                      >
                        Zapri
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
};

export default PredlogBlock;
