import React from "react";

export const TopBar = (props) => {
  return (
    <>
      <div className="mx-auto fixed z-50 top-0 w-full">
        <div className="shadow-md relative flex   h-14 md:h-20 p-3 items-center justify-between backdrop-blur-md bg-[rgba:(255,255,255,.9)] font-semibold">
          {props.naslov}
        </div>
      </div>
      <div className="h-14 md:h-20"></div>
    </>
  );
};
