import { React, useState, useRef, Fragment, useEffect, getDoc } from "react";
import {
  collection,
  query,
  doc,
  orderBy,
  where,
  setTasks,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import { db } from "../firebase";
import classNames from "classnames/bind";
import {
  ShoppingBagIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
  UserIcon,
  ListBulletIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

const tabs = [
  {
    name: "Info",
    index: 1,
    icon: InformationCircleIcon,
    current: false,
  },
  { name: "List", index: 2, icon: ListBulletIcon, current: false },
  { name: "Uporabniki", index: 3, icon: UserIcon, current: false },
];
const ModalSpecific = (props) => {
  const [infoScreen, SetInfoScreen] = useState(1);
  const [info, setInfo] = useState();
  function readData() {
    const mesta = collection(db, "mesta");
    const qu = query(mesta, where("ime", "==", props.ime));
    query(mesta, where("ime", "==", props.ime))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }
  /*const mesta = collection(db, "mesta");
  const qu = query(mesta, where("ime", "==", props.ime));
  const once = 0;
  const [
    currentDocumentData,
    currentDocumentDataLoadign,
    currentDocumentDataError,
  ] = useDocument(qu);
*/
  const probi = async () => {
    try {
      const docsSnap = await getDocs(
        query(collection(db, "mesta"), where("ime", "==", props.ime))
      );
      if (docsSnap.docs.length > 0) {
        docsSnap.forEach((doc) => {
          setInfo(doc.data());
          console.log(doc.data());
          console.log(doc.id);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    probi();
  }, []);
  const InfoScreenInfo = () => {
    return (
      <>
        {info && (
          <div>
            <h2 className="text-md font-medium mt-2">{info.ime}</h2>
            <h2 className="text-md font-medium mt-2">{info.telefon}</h2>
          </div>
        )}
      </>
    );
  };
  const InfoScreenList = () => {
    return <h2>Šoping list</h2>;
  };
  const InfoScreenUsers = () => {
    return <h2>Uporabniki</h2>;
  };
  return (
    <div>
      {" "}
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={() => SetInfoScreen(tab.index)}
                className={classNames(
                  tab.current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "cursor-pointer group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.current
                      ? "text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </div>
            ))}
          </nav>
        </div>
      </div>
      {infoScreen == 1 && <InfoScreenInfo />}
      {infoScreen == 2 && <InfoScreenList />}
      {infoScreen == 3 && <InfoScreenUsers />}
    </div>
  );
};

export default ModalSpecific;
