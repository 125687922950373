import { React, useState, useRef, Fragment, useEffect } from "react";
import { ShoppingBagIcon, CheckIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import Loader from "./Loader";
import classNames from "classnames";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  orderBy,
} from "firebase/firestore";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../firebase";
const OptionBlock = () => {
  const [podatki, setPodatki] = useState([{ ime: "najc", createdAt: "www" }]);
  const [notificationModal, setNotificationModal] = useState(false);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [ime, setIme] = useState("");
  const citiesRef = collection(db, "mesta");
  const q = query(citiesRef, orderBy("createdAt"));
  const addPredlog = async () => {
    try {
      await addDoc(collection(db, "predlogi"), {
        ime: ime,
        pobudnik: "Žan",
        createdAt: serverTimestamp(),
      });
    } catch (error) {
      console.log(error);
    }
  };
  const [value, loading, error] = useCollectionData(q, {
    snapshotListenOptions: { includeMetadataChanges: true },
    snapshotOptions: { idField: "id", orderBy: "createdAt" },
  });
  useEffect(() => {
    setPodatki(value);
  }, [value]);

  //console.log(podatki);
  /* setTimeout(() => {
    setPodatki(
      podatki.concat({
        ime: "neki",
        telefon: "04324353",
      })
    );
  }, 2000); */
  //console.log(podatki);
  if (1 < 0) {
    return <Loader />;
  } else {
    return (
      <>
        {podatki &&
          podatki.map((doc, key) => (
            <Fragment key={doc.id}>
              {" "}
              <div
                onClick={() => {
                  setOpen(true);
                  setIme(doc.ime);
                }}
                className={classNames(
                  "min-w-0 p-2 md:p-5 flex-1 opacity-0 rounded-sm bg-white shadow-md",
                  "animate-fadein "
                )}
                style={{ animationDelay: key / 10 + 0.2 + "s" }}
              >
                <a href="#" className="focus:outline-none">
                  <ShoppingBagIcon className="h-8 w-8 text-blue-500 " />
                  <p className="text-sm md:font-medium text-gray-900">
                    {doc.ime}
                  </p>
                  <p className="truncate text-sm text-gray-500">
                    {" "}
                    {doc.telefon}
                  </p>
                </a>
              </div>
            </Fragment>
          ))}
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {ime}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Ste prepričani, da želite predlagati {ime}?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                        onClick={() => {
                          setOpen(false);
                          addPredlog();
                        }}
                      >
                        Da
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Prekliči
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
};

export default OptionBlock;
