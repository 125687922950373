import { React, useState, useRef, Fragment } from "react";
import { query, collection, orderBy, where, doc } from "firebase/firestore";
import { db } from "../firebase";
import classNames from "classnames";
import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";
const GetMenu = ({ ime, selectOption }) => {
  const mesta = collection(db, "mesta", "LTpT8Vlwkig0yzRLd4E5", "meni");
  const qu = query(mesta, where("ime", "==", ime));

  const [value, loading, error] = useCollection(mesta, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const clickOption = (id) => {
    setSelectedValue(id);
  };
  //value && console.log(value);
  //value?.docs.map((doc) => console.log(doc.data()));
  const [selectedValue, setSelectedValue] = useState("");
  return (
    <>
      <div>
        {value &&
          value.docs.map((doc) => (
            <button
              type="button"
              key={doc.id}
              onClick={(e) => {
                selectOption(e, doc.data().opcije);
                clickOption(doc.id);
              }}
              className={classNames(
                "inline-block items-center mr-2 mt-1 rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium  hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                selectedValue === doc.id
                  ? "bg-indigo-500 text-white"
                  : "bg-indigo-100 text-indigo-700"
              )}
              value={doc.data().opcije}
            >
              {doc.id}
            </button>
          ))}
      </div>
    </>
  );
};

export default GetMenu;
