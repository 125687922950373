import { Outlet } from "react-router-dom";
import { React, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import Navbar from "../components/Navbar";
const LayoutNav = () => {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) navigate("/login");
  }, [user]);
  return (
    <>
      <Outlet />
      <Navbar />
    </>
  );
};

export default LayoutNav;
